<template>

<component :is="relatedView"/>


</template>

<script>
import Home from "@/views/Home.vue"
import MemberDashbord from "@/views/member/Dashboard.vue"
export default {
 

  components:{
    Home,
    MemberDashbord
  },
  computed:{
      relatedView(){
        let userInfo= JSON.parse(localStorage.userInfo)
        if(userInfo.role_id==1) return "home"
        return "member-dashbord"
      }
  }
}
  
</script>