<template>
  <div style="">
    <div class="timesheet-box">
      <b-row>
        <b-col cols="auto">
          <div class="timesheet-box-date">{{ currentDateTime() }}</div>
        </b-col>

        <b-col> </b-col>

        <b-col cols="auto" class="ml-auto mt-1">
          <div class="d-flex">
            <p style="margin-top: 4px">Ma feuille de temps</p>
            <router-link
              to="/time-sheet"
              class="b-avatar mb-1 mr-1 ml-1 badge-light-warning rounded-circle"
              style="width: 30px; height: 30px"
              ><span class="b-avatar-custom"
                ><feather-icon icon="ArrowRightIcon" size="25" /></span
            ></router-link>
          </div>
        </b-col>
        <b-col cols="12">
          <div style="background: #171b2b">
            <div class="d-flex">
              <h1 class="timesheet-hour-label pl-1">
                <b-alert class="text-center" show>
                  <DigitalClock :displaySeconds="false"
                /></b-alert>
              </h1>

              <div style="margin-left: 80px" class="d-flex timesheet-overview">
                <dd>
                  <span>Arrivée</span>
                  <div>
                    <strong class="time-state">{{
                      currentTimesheet
                        ? adjustTime(currentTimesheet.arrival) || "-"
                        : "-"
                    }}</strong>
                  </div>
                </dd>

                <dd>
                  <span>Début pause</span>
                  <div>
                    <strong class="time-state">{{
                      currentTimesheet
                        ? adjustTime(currentTimesheet.start_pause) || "-"
                        : "-"
                    }}</strong>
                  </div>
                </dd>
                <dd>
                  <span>Fin pause</span>
                  <div>
                    <strong class="time-state">{{
                      currentTimesheet
                        ? adjustTime(currentTimesheet.end_pause) || "-"
                        : "-"
                    }}</strong>
                  </div>
                </dd>

                <dd>
                  <span>Départ</span>
                  <div>
                    <strong>{{
                      currentTimesheet
                        ? adjustTime(currentTimesheet.departure) || "-"
                        : "-"
                    }}</strong>
                  </div>
                </dd>
              </div>

              <div
                class="hello"
                v-if="
                  currentTimesheet == null || currentTimesheet.last_action != 4
                "
              >
                <!-- <img
                  width="43"
                  :src="require('@/assets/images/christmas/hat.png')"
                  alt="Chirstmas Hat"
                  class="christmas-hat"
                /> -->
                <b-button
                  :disabled="progress"
                  class="ml-auto"
                  variant="warning"
                  @click="hello()"
                >
                  <b-spinner small v-if="progress == true" /> {{ helloText }}
                </b-button>
              </div>

              <b-btn
                v-show="
                  currentTimesheet != null && currentTimesheet.last_action == 4
                "
                @click="$router.push('/activities')"
                class="ml-auto"
              >
                Le bilan du jour est disponible
                <feather-icon icon="CornerRightDownIcon"></feather-icon
              ></b-btn>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row>
        <b-col md="4">
          <tache-en-cours-chart :data="stats.ongoing_tasks" />
        </b-col>
        <b-col md="4">
          <tache-en-retard-chart :data="stats.ongoing_tasks" />
        </b-col>
        <b-col md="4">
          <tache-achevee-chart :data="stats.ongoing_tasks" />
        </b-col>
      </b-row>
    </div>
    <b-card title="Envoi d'alerte">
      <validation-observer ref="sendAlertForm">
        <b-form>
          <b-row>
            <b-col md="5">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Message"
                  rules="required"
                >
                  <b-input-group>
                    <template #prepend style="border-radius: 10px">
                      <b-input-group-text>Message :</b-input-group-text>
                    </template>
                    <b-form-input
                      :rules="'required'"
                      type="text"
                      v-model="alert.message"
                      style="border-radius-right: 10px"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-input-group>
                <template #prepend style="border-radius: 10px">
                  <b-input-group-text>Debut :</b-input-group-text>
                </template>
                <b-form-input
                  type="datetime-local"
                  v-model="alert.start_date"
                  style="border-radius-right: 10px"
                />
              </b-input-group>
            </b-col>
            <b-col md="3">
              <b-input-group>
                <template #prepend style="border-radius: 10px">
                  <b-input-group-text>Fin :</b-input-group-text>
                </template>
                <b-form-input
                  type="datetime-local"
                  v-model="alert.end_date"
                  style="border-radius-right: 10px"
                />
              </b-input-group>
            </b-col>
            <b-col cols="auto">
              <div
                class="d-flex"
                style="position: absolute; left: 90%; top: 24px"
              >
                <b-spinner class="mt-n1" v-if="generateIsProgress" medium />
                <b-button
                  variant="gradient-primary"
                  class="add-story-btn btn-icon avatar rounded-circle"
                  @click="sendAlert"
                >
                  <feather-icon icon="SendIcon" />
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card no-body class="">
      <template #header>
        <b-row>
          <b-col cols="auto">
            <h2>Collaborateurs : ({{ employees_meta.total }})</h2>
          </b-col>
        </b-row>
      </template>
      <div class="">
        <b-table
          ref="refInvoiceListTable"
          :items="employeesWithStats"
          responsive
          :fields="userTableColumns"
          show-empty
          :busy="isLoading"
          empty-text="Pas de collaborateur disponible"
          class="position-relative"
        >
          <template #table-busy>
            <b-skeleton-table
              :rows="5"
              :columns="8"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
          </template>

          <template #head(invoiceStatus)>
            <feather-icon icon="TrendingUpIcon" class="mx-auto" />
          </template>

          <template #cell(name)="data">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  badge
                  :badge-variant="data.item.online ? 'success' : 'danger'"
                  badge-offset="-2px"
                  class="badge-minimal"
                  size="32"
                  :src="data.item.picture"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.firstname + " " + data.item.lastname }}
              </span>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-media>
          </template>

          <template #cell(count_finished)="data">
            <b-badge
              class="stat-badge"
              @click="toggleTableDetails(data, 'finished_tasks')"
              variant="success"
              >{{ data.item.count_finished }}</b-badge
            >
          </template>

          <template #cell(count_ongoing)="data">
            <b-badge
              @click="toggleTableDetails(data, 'ongoing_tasks')"
              class="stat-badge"
              variant="warning"
              >{{ data.item.count_ongoing }}</b-badge
            >
          </template>

          <template #cell(count_delayed)="data">
            <b-badge
              @click="toggleTableDetails(data, 'delayed_tasks')"
              class="stat-badge"
              variant="danger"
              >{{ data.item.count_delayed }}</b-badge
            >
          </template>

          <template #cell(count_billable)="data">
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks')"
              class="stat-badge opacity-50"
              variant="info"
              >{{ data.item.count_billable }}</b-badge
            >
          </template>

          <template #cell(count_billed)="data">
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks-billed')"
              class="stat-badge color-success"
              variant="success"
              >{{ data.item.count_billed }}</b-badge
            >
          </template>

          <template #cell(count_not_billed)="data">
            <b-badge
              @click="toggleTableDetails(data, 'billable-tasks-not-billed')"
              class="stat-badge color-danger"
              variant="danger"
              >{{ data.item.count_not_billed }}</b-badge
            >
          </template>

          <template class="stat-badge" #cell(count_total)="data">
            <b-badge variant="primary">{{ data.item.count_total }}</b-badge>
          </template>

          <template #row-details="row">
            <b-card>
              <b-table
                :key="table_key"
                show-empty
                empty-text="Aucune donnée"
                :fields="row_details_fields"
                :busy="isBusy"
                :items="row_details_items[row.item.id]"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Chargement...</strong>
                  </div>
                </template>

                <template #cell(is_finished)="data" class="task-respo">
                  <b-form-checkbox
                    :disabled="_getTaskCheckBoxState(userInfo, data.item)"
                    v-model="data.item[getKey(data.item)]"
                    :indeterminate="
                      ((!data.item.is_closed && data.item.is_finished) ||
                        (data.item.supervisor_id &&
                          data.item.is_finished &&
                          !data.item.is_closed)) == 1
                        ? true
                        : false
                    "
                    class="custom-control-primary"
                    :value="1"
                    @change="_taskChecked(data.item)"
                  >
                  </b-form-checkbox>
                </template>
                <template #cell(infos)="data">
                  <div>
                    <b>Responsable</b> {{ getMaster(data.item) }} <br />
                    <b>Superviseur</b>: {{ getSupervisor(data.item) }}
                  </div>
                </template>
                <template #cell(activity)="data">
                  <b-btn
                    :disabled="
                      userInfo.id != data.item.user_id &&
                      userInfo.id != data.item.supervisor_id
                    "
                    @click="
                      $router.push('/task/' + data.item.id + '/changelogs')
                    "
                  >
                    <feather-icon icon="ActivityIcon"></feather-icon>
                  </b-btn>
                </template>
              </b-table>
              <br />

              <b-button size="sm" @click="row.toggleDetails">Fermer</b-button>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="employees_meta.total"
            :per-page="employees_meta.per_page"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-1 ml-1"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>

        <div id="chart"></div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import DigitalClock from "vue-digital-clock";
import { mapActions, mapState } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import TacheEnCoursChart from "@/views/charts/TacheEnCoursChart.vue";
import TacheEnRetardChart from "@/views/charts/TacheEnRetardChart .vue";
import TacheAcheveeChart from "@/views/charts/TacheAcheveeChart.vue";
import axios from "@/libs/axios";
import moment from "moment";
export default {
  components: {
    TacheAcheveeChart,
    TacheEnRetardChart,
    TacheEnCoursChart,
    BCard,
    BCardText,
    BLink,
    FeatherIcon,
    ToastificationContent,
    DigitalClock,
  },
  data() {
    return {
      isLoading: true,
      progress: true,
      notificationsSupported: false,
      serviceWorkerRegistation: null,
      subscription: null,
      generateIsProgress: false,
      errorModal: "",
      errors: "",
      loading: false,
      alert: {},
      currentPage: 1,
      alertDefault: {
        message: "",
        start_date: "",
        end_date: "",
        user_id: "",
      },
      filterData: {
        user_id: null,
        table_size: 10,
        page: 1,
      },

      show_hello_btn: false,
      mainProps: {
        blank: true,
        width: 300,
        height: 75,
        class: "m2",
      },

      userTableColumns: [
        { key: "name", sortable: true, label: "Noms et prénoms" },
        /*         { key: "email", sortable: true, formatter: (val) => `$${val}` },
         */ {
          key: "count_ongoing",
          label: "Tâche en cours",
          sortable: true,
        },
        { key: "count_finished", label: "Tâche achevées", sortable: true },

        { key: "count_delayed", label: "Tâche en retard", sortable: true },
        { key: "count_billable", label: "Tâches facturables", sortable: true },
        { key: "count_billed", label: "Tâche facturées", sortable: true },
        {
          key: "count_not_billed",
          label: "Tâches non facturées",
          sortable: true,
        },
        { key: "count_total", label: "Total", sortable: true },
      ],

      table_key: 0,
      isBusy: false,
      row_details_fields: [
        { key: "is_finished", label: "Achevé" },

        { key: "name", label: "Nom de la tâche" },
        { key: "start_date", label: "Date de début" },
        { key: "end_date", label: "Date de fin" },
        { key: "infos", label: "Infos" },

        { key: "activity", label: " Voir Les Activités" },
      ],
      row_details_items: [],
    };
  },
  computed: {
    ...mapState("timesheet", ["currentTimesheet"]),
    ...mapState("enterprise", [
      "stats",
      "employeesWithStats",
      "employees",
      "employees_meta",
    ]),
    ...mapState("alert", ["alerts"]),
    ...mapState("auth", ["userInfo"]),

    helloText() {
      switch (this.currentTimesheet?.last_action) {
        case 1:
          return "Je prends la pause";
        case 2:
          return "Je viens de la pause";
        case 3:
          return "Bonsoir à tous";
        default:
          return "Bonjour à tous";
      }
    },

    currenteHelloText() {
      switch (this.currentTimesheet?.last_action) {
        case 1:
          return "Bonjour à tous";
        case 2:
          return "Je prends la pause";
        case 3:
          return "Je viens de la pause";
        default:
          return "Bonsoir à tous";
      }
    },

    notificationState() {
      return this.$store.state.notificationsEnabled;
    },
  },

  created() {
    if ("Notification" in window && "serviceWorker" in navigator) {
      this.notificationsSupported = true;
    }
  },

  methods: {
    getMaster(task) {
      if (task.master?.id == this.userInfo.id) {
        return "Moi";
      } else {
        return task.master?.firstname || "-";
      }
    },
    getSupervisor(task) {
      if (task.supervisor?.id == this.userInfo.id) {
        return "Moi";
      } else {
        return task.supervisor?.firstname || "-";
      }
    },

    getKey(task) {
      if (
        task.supervisor_id == this.userInfo.id ||
        (task.is_finished == 1 && this.userInfo.id == 19) ||
        (task.is_finished == 1 && this.userInfo.id == 5)
      )
        return "is_closed";
      if (task.supervisor_id == null) return "is_closed";
      if (task.user_id == this.userInfo.id) return "is_finished";
    },

    _taskChecked(task) {
      this.taskChecked({ task: task, userInfo: this.userInfo }).then((r) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Opération éffectuée.",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },

    ...mapActions("timesheet", ["getcurrentTimesheet", "greet"]),
    ...mapActions("alert", ["addAlert", "fetchAlert", "showAlert"]),

    ...mapActions("enterprise", [
      "getStats",
      "getEmployeesWithStats",
      "getEmployees",
    ]),
    ...mapActions("tasks", ["taskChecked"]),
    toggleTableDetails(row, route) {
      this.isBusy = true;
      this.fetchRowDetailsItems(row, route).then((r) => {
        this.isBusy = false;
        this.row_details_items[row.item.id] = r.data.data;
        this.table_key++;
      });

      row.toggleDetails();
    },

    fetchRowDetailsItems({ item }, route) {
      return new Promise((resolve, reject) => {
        axios.get("user/" + item.id + "/" + route).then((r) => {
          resolve(r);
        });
      });
    },
    async sendAlert() {
      const valid = await this.$refs.sendAlertForm.validate();
      if (!valid) return;
      this.generateIsProgress = true;
      this.sendGroupNotification(this.userInfo, this.alert.message);
      this.addAlert(this.$formData(this.alert))
        .then((r) => {
          this.generateIsProgress = false;
          this.clearForm();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alerte envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.loading = true;
          this.errorModal = true;
          this.errors = error.response.data;
        });
    },

    clearForm() {
      this.alert = this.alertDefault;
      this.$nextTick().then((response) => {
        this.$refs.sendAlertForm.reset();
      });
    },
    currentDateTime() {
      const current = new Date();
      var date = moment(current).locale("fr").format("dddd Do MMMM YYYY");
      return date.charAt(0).toUpperCase() + date.slice(1);
      //  return dateTime;
    },

    adjustTime(time) {
      if (time) {
        var date_system = new Date();
        var date_time = new Date("08/05/2015 " + time);

        const timezone = -date_system.getUTCHours() + date_system.getHours();
        date_time.setHours(date_time.getHours() + timezone);
        var options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

        return date_time.toLocaleTimeString("fr-FR", options);
      } else {
        return "";
      }
    },

    sendGroupNotification(user, message) {
      const data = {};
      data.sender = user;
      data.message = message;

      this.$store.dispatch("notify/sendGroupNotification", data);
    },

    hello() {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous confirmer cette action ?", {
          title: "Information",
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          cancelVariant: "outline-primary",
          okTitle: "Oui, je confirme",
          cancelTitle: "Non, je refuse",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.progress = true;
            this.greet().then((r) => {
              this.progress = false;
              this.sendGroupNotification(
                r.data.data.user,
                this.currenteHelloText
              );
            });
          }
        })
        .catch((err) => {});
    },

    //------Start Notify -----
    toggleSubscription() {
      if (this.notificationsSupported) {
        // Find out if we need to create a subscription or delete it
        if (!this.notificationState) {
          if (Notification.permission === "denied") {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notifications",
                  icon: "BellIcon",
                  text: "Les notifications sont bloquées. Veuillez les activer dans votre navigateur.",
                  variant: "warning",
                },
              },
              {
                position: "bottom-left",
                timeout: 5688999,
              }
            );
          }
          try {
            // Ask permission and when granted, create new subscription
            Notification.requestPermission().then((result) => {
              // if granted, create new subscription
              if (result === "granted") {
                this.createSubscription()
                  .then((sub) => {
                    this.subscription = sub;
                  })
                  .then(() => {
                    // store new subscription on the server
                    this.$store.dispatch("notify/subscribe", {
                      subscription: this.subscription,
                      user_id: this.userInfo.id,
                    });
                  })
                  .then(() => {
                    this.$store.dispatch("notify/enableNotification", true);
                  });
              } else {
              }
            });
          } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it
            // throws a TypeError. It takes a callback as the first argument
            // instead.
            if (error instanceof TypeError) {
              //to do --R
            } else {
              throw error;
            }
          }
        } else {
          // Destroy subscription
          if (this.subscription !== null) {
            // destroy on the server
            return (
              this.$store
                .dispatch("notify/deleteSubscription", {
                  endpoint: this.subscription.endpoint,
                })
                // unsubscribe on the client
                .then(() => this.subscription.unsubscribe())
                .then(() => {
                  // update the data
                  this.$store.dispatch("notify/enableNotification", false);
                  this.subscription = null;
                })
            );
          }
        }
      }
    },
    createSubscription() {
      if (this.serviceWorkerRegistation === null) {
        return navigator.serviceWorker.ready // returns a Promise, the active SW registration
          .then((swreg) => {
            this.serviceWorkerRegistation = swreg;
            return this.subscribe(this.serviceWorkerRegistation);
          });
      } else {
        return this.subscribe(this.serviceWorkerRegistation);
      }
    },
    getSubscription(swreg) {
      return swreg.pushManager.getSubscription();
    },
    subscribe(swreg) {
      // create new subscription for this browser on this device
      const vapidPublicKey =
        "BFkqIR0xKEIsCfQx4EfCvAPzdkOOl5opLkqwqhR4wH_4GqldwDa99lQqB0wnedZMsMnKXv7IZGi4mRCbQOSs01c";
      const convertedVapidPublicKey =
        this.urlBase64ToUint8Array(vapidPublicKey);
      // return the subscription promise, we chain another then where we can send it to the server
      return swreg.pushManager.subscribe({
        userVisibleOnly: true,
        // This is for security. On the backend, we need to do something with the VAPID_PRIVATE_KEY
        // that you can find in .env to make this work in the end
        applicationServerKey: convertedVapidPublicKey,
      });
    },

    async findSubscription() {
      return navigator.serviceWorker.ready.then((swreg) => {
        this.serviceWorkerRegistation = swreg;
        return this.getSubscription(this.serviceWorkerRegistation);
      });
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      let outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    //----End Notify -----

    _getTaskCheckBoxState(user, task) {
      if (task.is_finished == 1 && user.id == 5) return false;
      if (task.is_finished == 1 && user.id == 19) return false;
      if (task.user_id != user.id && task.supervisor_id != user.id) return true;
      if (task.user_id == user.id && task.supervisor_id == null) return false;
      if (task.supervisor_id == user.id && task.user_id == user.id)
        return false;
      if (task.supervisor_id == user.id && task.is_finished == 0) return true;
      if (task.user_id == user.id && task.is_closed == 1) return true;
    },
  },
  watch: {
    currentPage(val) {
      this.getEmployeesWithStats(this.filterData);
    },
  },
  mounted() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    this.getcurrentTimesheet().then((r) => {
      this.show_hello_btn = true;
      this.progress = false;
      this.getEmployeesWithStats(this.filterData).then(() => {
        this.getEmployees().then(() => {
          this.isLoading = false;
        });
      });
    });

    this.toggleSubscription();
    // Find out if the user has a subscription at the moment.
    // If so, update the enabled flag in data
    this.findSubscription().then((sub) => {
      if (sub === null) {
        this.$store.dispatch("notify/enableNotification", false);
      } else {
        // retrieve user info from API
        this.$store.dispatch("notify/enableNotification", true);
        this.subscription = sub;
      }
    });
  },
};
</script>

<style>
.opacity-50 {
  opacity: 0.8;
}

.color-success {
  background-color: rgb(75, 114, 75);
}

.color-danger {
  background-color: rgb(209, 87, 87);
}

div .hello {
  position: absolute;
  right: 4%;
}

.christmas-hat {
  transform: translate(18px, -16px) rotate(-30deg);
}

.add-story-card {
  width: 71%;
  position: absolute;
  bottom: 10px;
  background: white;
  height: 50px;
  margin-bottom: 5px;
}

.timesheet-overview dd {
  margin-left: 30px;
}

.timesheet-box-date {
  padding: 19px;
  height: 100%;
  background: #171b2b;
  color: #c5cce9;
}
.timesheet-box {
  background: #0f111e;
  border-top: 2px solid #c5cce9;
  border-bottom: 2px solid #c5cce9;
  margin-bottom: 10px;
}
.timesheet-hour-label {
  color: #23aa82;
  margin-top: 3px;
  margin-left: 4px;
  width: 120px;
}
.time-state {
  color: #23aa82;
}
.add-story-btn {
  margin-top: -24px;
  margin-left: 30px;
  border: 3px solid white;
}

.avatar {
  margin-left: 10px;
}

.employees-card {
  margin-right: 7% !important;
}
.add-story-card {
  width: 71%;
  position: absolute;
  bottom: 10px;
  background: white;
  height: 50px;
  margin-bottom: 5px;
}

.add-story-btn {
  margin-top: -24px;
  margin-left: 30px;
  border: 3px solid white;
}

.avatar {
  margin-left: 10px;
}

.employees-card {
  margin-right: 9% !important;
}

.fond {
  background: #025288;
  background: radial-gradient(ellipse at center, #187088 0%, #1e4797 70%);
  background-size: 100%;
}

#clock {
  font-family: "Share Tech Mono", monospace;
  color: #ffffff;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
}
.time {
  letter-spacing: 0.05em;
  font-size: 30px;
  padding: 5px 0;
}
.date {
  letter-spacing: 0.1em;
  font-size: 15px;
}
.space {
  height: 88%;
}

/* BUTTON STYLE */

.btn-three::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 2px solid rgba(255, 255, 255, 0.5);
  transform: scale(1.2, 1.2);
}
.btn-three:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #0c1a6d,
    #134083,
    #3c25be,
    #0c047e,
    #073e85,
    #002bff,
    #092ea7
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
  margin-top: 2px;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(4, 13, 95);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.btn-admin {
  margin-top: 50%;
}

.stat-badge {
  cursor: pointer;
}
</style>
