<template>
  <b-card class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1"> Tâches achevées </b-card-title>

        <b-card-text>
          <div class="font-small-2 pt-2">Total</div>
          <h4>{{ stats.finished_tasks }}</h4>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="chartOptions"
          :series="[finishedPer]"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";

const $earningsStrokeColor2 = "#28a745d4";
const $earningsStrokeColor3 = "#28a74559";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },

  data() {
    return {
      chartOptions: {
        chart: {
          type: "radialBar",
        },
        dataLabels: {
          show: false,
        },

        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                color: $themeColors.success,
                fontSize: "14px",
              },
            },
          },
          dataLabels: {
            showOn: "always",

            name: {
              offsetY: -10,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              color: "#111",
              fontSize: "30px",
              show: true,
            },
          },
        },

        stroke: { width: 0 },
        colors: [$themeColors.success],
        grid: {
          padding: {
            right: -50,
            bottom: -20,
            left: -50,
          },
        },
      },
    };
  },
  computed: {
    ...mapState("enterprise", ["stats"]),

    finishedPer() {
      var myPer = this.stats.finished_tasks
        ? (100 * this.stats.finished_tasks) /
          (this.stats.finished_tasks + this.stats.ongoing_tasks + this.stats.delayed_tasks)
        : 0;
      return myPer.toFixed(1);
    },
  },
  methods: {
    ...mapActions("enterprise", [
      "getStats",
    ]),
  },
  mounted() {
    this.getStats();
  },
};
</script>
